import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';
import BlobImageComponent from "./ImageWithText";

const SearchBar = ({ data, projectFound, setProjectFound }) => {
    const [enteredTitle, setEnteredTitle] = useState('');
    const [foundProjects, setFoundProjects] = useState([]);
    let searchedPost = [];
    const [isValid, setIsValid] = useState(false);
    const { edges: allPosts } = data.allMarkdownRemark;
    function inputHandler(e) {
        e.preventDefault();
        setEnteredTitle(e.target.value);
    };
    useEffect(() => {
        const identifier = setTimeout(() => {
            if (enteredTitle === '') {
                setIsValid(false);
            } else {
                setIsValid(true);
            }
            if (isValid && enteredTitle.length > 1) {
                searchedPost = allPosts.filter(({ node: thisPost }) => {
                    //return enteredTitle.toLowerCase().includes(thisPost.frontmatter.title.toLowerCase());
                    return thisPost.frontmatter.title.toLowerCase().includes(enteredTitle.trim().toLowerCase());
                });
                setFoundProjects(searchedPost);
            }
            if (searchedPost.length > 0) {
                setProjectFound(true);
            } else {
                setProjectFound(false);
            }
        }, 300);
        return () => {
            clearTimeout(identifier);
        };
    }, [enteredTitle, projectFound]);
    return (
        <React.Fragment>
            <div className="field">
                <label className="label is-size-4" htmlFor={"name"}>
                    Are you looking for a specific project?
                </label>
                <p>Type its title here:</p>
                <div className="control">
                    <input
                        className="input"
                        type={"text"}
                        name={"search"}
                        id={"search"}
                        placeholder="e.g. community"
                        value={enteredTitle}
                        onChange={inputHandler}
                    />
                </div>
                <br></br>
                <br></br>
            </div>
            {isValid && (<div className="columns is-multiline">
                {foundProjects?.map((post, index) => (
                    <div className="column is-parent is-4" key={post.node.id}>
                        <article className="columns is-mobile is-centered">
                            <header>
                                <div>
                                    <Link to={post.node.fields.slug} className="has-text-white pointer">
                                        <BlobImageComponent
                                            title={post.node.frontmatter.title}
                                            index={index}
                                            // subheading={tag.totalCount}
                                            width={250}
                                            height={250}
                                            textSize={26}
                                        />
                                    </Link>
                                </div>
                            </header>
                        </article>
                        <br></br>
                        <br></br>
                    </div>
                ))}
            </div>)}
        </React.Fragment>
    );
}

SearchBarQuery.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
};

export default function SearchBarQuery(props) {
    return (
        <StaticQuery
            query={graphql`
          query explorePageQuery {
            allMarkdownRemark(
              sort: { order: ASC, fields: [frontmatter___title] }
              filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            ) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    mediaType
                    responseTo
                    nameOfArtists {
                      artist
                    }
                  }
                }
              }
            }
          }
        `}
            render={(data) => <SearchBar data={data} {...props} />}
        />
    );
}
