import React, { useState } from "react";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";
import BlobImageComponent from "../../components/ImageWithText";
import SearchBar from "../../components/SearchBar";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const [projectFound, setProjectFound] = useState(false);
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            {/* <h1>Exlpore</h1> */}
            <div>
              {/* <Helmet title={`Tags | ${title}`} /> */}
              <SearchBar projectFound={projectFound} setProjectFound={setProjectFound} />
              {!projectFound && (
                <div className="columns is-multiline">
                  {group.map((tag, index) => (
                    <div className="is-parent column is-4" key={tag.fieldValue}>
                      <article className="columns is-mobile is-centered">
                        <header>
                          <div>
                            <Link className="has-text-white pointer gray-images" to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                              <BlobImageComponent
                                title={tag.fieldValue}
                                index={index}
                                // subheading={tag.totalCount}
                                width={250}
                                height={250}
                                textSize={26}
                              />
                            </Link>
                            <span>
                              <br></br>
                            </span>
                          </div>

                        </header>
                      </article>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
